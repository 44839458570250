import styled from 'styled-components';

export const IconScroll = styled.div`
    &:before {
        position: absolute;
        left: 50%;
    }

    :before {
        content: '';
        width: 8px;
        height: 8px;
        background: black;
        margin-left: -4px;
        top: 8px;
        border-radius: 4px;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-name: scroll;
    }

    width: 40px;
    height: 70px;
    margin-left: -20px;
    top: 50%;
    margin-top: -35px;
    box-shadow: inset 0 0 0 1px black;
    border-radius: 25px;

    @keyframes scroll {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
            transform: translateY(46px);
        }
    }
`