import React from 'react';
import { FaDribbble, FaGithub, FaLinkedinIn, FaMediumM } from 'react-icons/fa';

import { 
    SidebarContainer, 
    SidebarInfo,
    SidebarMenu, 
    SidebarWrapper, 
    SidebarLink, 
    SidebarLink2,
    SideSocialIconsWrap, 
    SocialLinks,
    AlignLink
} from './SidebarElements'

const Sidebar = ({isOpen, toggle}) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>

            <SidebarWrapper>
        
                <SidebarMenu>
                    <SidebarLink smooth to='/#about_me' smooth={true} duration={500} spy={true} offset={-300} onClick={toggle}>
                        About me
                    </SidebarLink>
                    <SidebarLink smooth to='/#work' smooth={true} duration={500} spy={true} offset={-200} onClick={toggle}>
                        Work
                    </SidebarLink>
                    <SidebarLink smooth to='/#contact' smooth={true} duration={500} spy={true} onClick={toggle}>
                        Contact
                    </SidebarLink>
                    <SidebarLink2 onClick={toggle}>
                        <a href="https://drive.google.com/file/d/1Pc-N8z2S3vuJ-XzXkkb1J9wCVTRVAW48/view?usp=sharing" target="_blank">
                            Resume
                        </a>
                    </SidebarLink2>
                </SidebarMenu>
                
                <SideSocialIconsWrap>
                    <SocialLinks>
                        <a href="https://dribbble.com/Tim_iliev" target="_blank">
                            <AlignLink><FaDribbble style={{ fill: 'black', marginRight: 10}}/>Dribbble</AlignLink>
                        </a>
                    </SocialLinks>
                    <SocialLinks>
                        <a href="https://github.com/timiliev" target="_blank">
                            <AlignLink><FaGithub style={{ fill: 'black', marginRight: 10 }}/>Github</AlignLink>
                        </a>
                    </SocialLinks>
                    <SocialLinks>
                        <a href="https://www.linkedin.com/in/timotejiliev/" target="_blank">
                            <AlignLink><FaLinkedinIn style={{ fill: 'black', marginRight: 10 }}/>Linkedin</AlignLink>
                        </a>
                    </SocialLinks>
                    <SocialLinks>
                        <a href="https://medium.com/@timiiliev" target="_blank">
                            <AlignLink><FaMediumM style={{ fill: 'black', marginRight: 10 }}/>Medium</AlignLink>
                        </a>
                    </SocialLinks>                            
                </SideSocialIconsWrap>

                <SidebarInfo>
                    Designed in Adobe XD. Developed with ReactJS.
                </SidebarInfo>

            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar
