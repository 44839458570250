import styled from 'styled-components'
import the_pulse_tile from "../../images/tiles/the_pulse_tile_cover.jpg";
import the_pulse_tile_mobile from "../../images/tiles/the_pulse_tile_cover_mobile.png";
import ravens_tile from "../../images/tiles/ravens_tile_cover.png";
import WCHS_tile from "../../images/tiles/wchs_tile_cover.png";

export const WorkContainer = styled.div`
    width: 100%;
`

export const WorkFullScreenContent = styled.div`
    display: flex;
    justify-content: center;
    margin-top: -70px;
`

export const WorkHeaderContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
`

export const WorkRegularContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    max-width: 1200px;
    margin: 0 auto;
`

export const FeaturedCaseStudyContainer = styled.div`
    display: flex;
    flex-direction: column;

    margin: -6rem 24px 4rem;

    /* background-color: lightblue; */
`

export const FeaturedTileCover = styled.div`
    /* width: 100%; */
    height: 400px;

    /* background-color: yellowgreen; */

    object-fit: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;
    background-image: url(${the_pulse_tile});

    @media screen and (max-width: 770px) {
        height: 350px;
        background-size: 700px;
        /* object-fit: cover; */
    }

    @media screen and (max-width: 600px) {
        background-size: 580;
    }
`

export const FeaturedTileCoverMobile = styled.div`
    /* width: 100%; */
    height: 400px;

    /* background-color: yellowgreen; */

    object-fit: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;
    background-image: url(${the_pulse_tile_mobile});

    @media screen and (max-width: 800px) {
        /* height: 350px; */
        background-size: auto 120%;
    }

    @media screen and (max-width: 500px) {
        height: 350px;
        background-size: auto 110%;
    }
`

export const FeaturedCaseStudyTag = styled.p`
    font-weight: 300;
    margin-top: 1rem;
    margin-bottom: 1.2rem;
    opacity: 50%;
`

export const FeaturedCaseStudyHeader = styled.h2`
    font-size: 25px;
`

export const FeaturedCaseStudyToolsTags = styled.h3`
    font-size: 16px;
    font-weight: 300;
    margin-top: 0.5rem;
    opacity: 50%;
`

export const ProjectPreviewsContainer = styled.div`
    display: grid;

    /* grid-template-columns: repeat(auto-fit, minmax(5000px, 1fr)); */

    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 3rem;
    grid-row-gap: 4rem;
    margin: 1rem 24px 4rem;

    @media screen and (max-width: 1024px) {
        grid-template-columns: 1fr;
    }
`

export const ProjectPreview = styled.div`
    width: 100%;
`

export const ProjectTile = styled.div`
    height: 350px;
    background-color: lightgray;
    opacity: 50%;
`

export const ProjectTileRavens = styled.div`
    height: 350px;

    object-fit: cover;
    background-repeat: no-repeat;
    background-position: center center;

    background-size: auto 100%;

    background-image: url(${ravens_tile});

    @media screen and (max-width: 1024px) {
        background-size: 100% auto;
        height: 400px;
        background-position: center center;
    }
`

export const ProjectTileWCHS = styled.div`
    height: 350px;

    object-fit: cover;
    background-repeat: no-repeat;
    background-position: center center;

    background-size: auto 100%;

    background-image: url(${WCHS_tile});

    @media screen and (max-width: 1024px) {
        background-size: 100% auto;
        height: 400px;
        background-position: center center;
    }
`

export const MoreCaseStudiesHeader = styled.h2`
    font-size: 25px;
    font-weight: 700;
    margin: 0 24px;
`

export const ProjectPreviewTitle = styled.h3`
    font-size: 22px;
    font-weight: 700;
    margin-top: 1rem;
`

export const ProjectPreviewDescription = styled.h4`
    font-size: 18px;
    font-weight: 400;
    margin-top: 0.8rem;
`

export const ProjectPreviewTags = styled.p`
    font-size: 16px;
    font-weight: 300;
    opacity: 50%;
    margin-top: 0.8rem;
`

export const ProjectPreviewBlue = styled.div`
    width: 100%;
    /* background-color: blue; */
`

export const ProjectPreviewRed = styled.div`
    width: 100%;
    /* background-color: red; */
`