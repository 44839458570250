import './App.css';

import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Home from './pages/Home';
import AboutMe from './components/AboutMe';
import Footer from './components/Footer';
import ThePulse from './components/CaseStudies/ThePulse';
import ScrollToTop from "./components/ScrollToTop";

// import ThePulseCaseStudy from './pages/ThePulseCaseStudy';

const App = () => {
  const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    };

  return (    
    <Router>
      <ScrollToTop />
      <Sidebar isOpen={isOpen} toggle={toggle}/>
      <Navbar id="header" class="" isOpen={isOpen} toggle={toggle}/>
      
      {/* <ThePulse /> */}

      <Switch>
         <Route path="/" component={Home} exact/>
         <Route path="/the-pulse-case-study" component={ThePulse} exact/>
         <Route path="/about_me" component={AboutMe}/>
      </Switch>
      
      <Footer />
    </Router>
    
    // <Router>
    //   <Switch>
    //     {/* <Route path="/" component={Home} exact/>
    //     <Route path="/the-pulse-case-study" component={ThePulseCaseStudy} exact/> */}
    //   </Switch>
    //   {/* <Footer /> */}
    // </Router>
  );
}

export default App;