import React from 'react'

import {
    WorkContainer,
    WorkFullScreenContent,
    WorkHeaderContainer,
    WorkRegularContent,
    FeaturedCaseStudyContainer,
    FeaturedTileCover,
    FeaturedTileCoverMobile,
    FeaturedCaseStudyTag,
    FeaturedCaseStudyHeader,
    FeaturedCaseStudyToolsTags,
    MoreCaseStudiesHeader,
    ProjectPreviewsContainer,
    ProjectTile,
    ProjectTileRavens,
    ProjectTileWCHS,
    ProjectPreviewTitle,
    ProjectPreviewDescription,
    ProjectPreviewTags,
    ProjectPreview,
    ProjectPreviewBlue,
    ProjectPreviewRed
} from "./WorkElements";

import the_pulse_tile from "../../images/tiles/the_pulse_tile_cover.jpg";
import ravens_tile from "../../images/tiles/ravens_tile_cover.png";
import WCHS_tile from "../../images/tiles/wchs_tile_cover.png";
import brew_crew_tile from "../../images/tiles/brew_with_the_crew_tile_cover.png";
import flame_tile from "../../images/tiles/match_illustration_tile_cover.png";
import work_header_image from "../../images/work/tape_top_and_bottom.png";
import party_blob from "../../images/work/party_blob.gif";

const Work = () => {
    return (
        <>
            <WorkContainer id="work" class="fadein_on_land">
                <WorkFullScreenContent>
                    <WorkHeaderContainer>
                        <img 
                            src={work_header_image} 
                            style={{minWidth: 1800, maxWidth: 3000}} 
                            class="" 
                            alt="Work header image"
                        />
                    </WorkHeaderContainer>
                </WorkFullScreenContent>

                <WorkRegularContent>
                    <FeaturedCaseStudyContainer>
                        <a href="/the-pulse-case-study">
                            {/* <FeaturedTileCover /> */}
                            <FeaturedTileCoverMobile />

                            <FeaturedCaseStudyTag>
                                    <span>
                                        <img 
                                            src={party_blob} 
                                            // class="span_picture_small opacity_emoji"
                                            class="span_picture_small" 
                                            alt="Waving hand emoji"/>
                                    </span>
                                    &nbsp;&nbsp;
                                    {/* <span class="opacity_50">Featured Case Study</span> */}
                                    <span>Featured Case Study</span>
                                </FeaturedCaseStudyTag>
                                <FeaturedCaseStudyHeader>
                                    <span class="bold_weight">The Pulse</span>
                                        <span class="regular_weight">
                                            &nbsp;━━ An award winning newspaper at&nbsp;
                                            <a href="https://www.findlay.edu/" target="_blank" class="orange_text underlined_text">
                                                The University of Findlay
                                            </a>.
                                        </span>
                                </FeaturedCaseStudyHeader>
                            <FeaturedCaseStudyToolsTags>
                                Branding / Editorial Design / Web Design
                            </FeaturedCaseStudyToolsTags>
                        </a>
                    </FeaturedCaseStudyContainer>
                    

                    <MoreCaseStudiesHeader>More case studies coming soon</MoreCaseStudiesHeader>
                    <ProjectPreviewsContainer>
                        <ProjectPreview>

                            {/* <ProjectTileRavens /> */}

                            <img 
                                src={ravens_tile} 
                                // style={{minWidth: 2000, maxWidth: 3000}} 
                                // class="" 
                                // alt="Work header image"
                            />

                            <ProjectPreviewTitle>
                                Raven's Progressive Matrices
                            </ProjectPreviewTitle>

                            <ProjectPreviewDescription>
                                A cross-platform compatible software for solving a non-verbal fluid intelligence test.
                            </ProjectPreviewDescription>     

                            <ProjectPreviewTags>
                                UI Design / Front-end development / HCI Research
                            </ProjectPreviewTags> 

                        </ProjectPreview>
                        
                        <ProjectPreview>

                            {/* <ProjectTileWCHS /> */}

                            <img 
                                src={WCHS_tile} 
                                // style={{minWidth: 2000, maxWidth: 3000}} 
                                // class="" 
                                // alt="Work header image"
                            />

                            <ProjectPreviewTitle>
                                Donor Management Software
                            </ProjectPreviewTitle>


                            <ProjectPreviewDescription>
                                A Customer Relationship Management (CRM) solution for&nbsp;
                                <a href="https://www.wchumane.org/" target="_blank" class="blue_text underlined_text"> 
                                    Wood County Humane Society
                                </a>
                                .
                            </ProjectPreviewDescription>     

                            <ProjectPreviewTags>
                                UI Design / Front-end development / Back-end development
                            </ProjectPreviewTags> 

                        </ProjectPreview>
                    </ProjectPreviewsContainer>


                    <MoreCaseStudiesHeader>Playground</MoreCaseStudiesHeader>
                    <ProjectPreviewsContainer>
                        <ProjectPreview>

                            {/* <ProjectTileRavens /> */}

                            <img 
                                src={brew_crew_tile} 
                                // style={{minWidth: 2000, maxWidth: 3000}} 
                                // class="" 
                                // alt="Work header image"
                            />

                            <ProjectPreviewTitle>
                                Brew With The Crew ━━ Cover Redesign
                            </ProjectPreviewTitle>

                            <ProjectPreviewDescription>
                                A concept redesign for&nbsp;  
                                <a 
                                    href="https://open.spotify.com/show/6OEDRyyzyP2EgOOw8Ai4mP" 
                                    target="_blank" 
                                    class="green_text underlined_text"
                                    > 
                                        Brew With The Crew
                                </a>
                                ━━ A University of Findlay podcast.
                            </ProjectPreviewDescription>     

                            <ProjectPreviewTags>
                                Graphic Design / Concept
                            </ProjectPreviewTags> 

                        </ProjectPreview>
                        
                        <ProjectPreview>

                            {/* <ProjectTileWCHS /> */}

                            <img 
                                src={flame_tile}
                                class="flame_border" 
                                // alt="Work header image"
                            />

                            <ProjectPreviewTitle>
                                Match Flame Illustration
                            </ProjectPreviewTitle>


                            <ProjectPreviewDescription>
                                An Illustration done for a design competition.
                            </ProjectPreviewDescription>     

                            <ProjectPreviewTags>
                                Graphic Design / Illustration
                            </ProjectPreviewTags> 

                        </ProjectPreview>
                    </ProjectPreviewsContainer>
                </WorkRegularContent>
            </WorkContainer>
        </>
    )
}

export default Work
