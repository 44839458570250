import React from 'react'
import { 
    HeroContainer, 
    HeroContent, 
    HeroLogoWrap,
    HeroH1, 
    HeroSubtext,
    HighlightedText 
} from './HeroElements';

import ScrollIcon from "../ScrollIcon";

import logo_black from "../../logo/logo_black.png";
import logo_white from "../../logo/logo_white.png";

const Hero = () => {
    return (
        <>
            <HeroContainer id="home" class="fadein_on_land"> 
                <HeroContent>
                    <HeroLogoWrap><img src={logo_black} class="" alt="Timotej Iliev logo"/></HeroLogoWrap>
                    
                    <HeroH1>
                        Designer, <br/>
                        Developer, <br />
                        HCI* researcher
                    </HeroH1>
                    <HeroSubtext>*Human Computer Interaction</HeroSubtext>

                    
                    {/* <ScrollIcon /> */}

                </HeroContent>
            </HeroContainer>
        </>
    )
}

export default Hero