import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { IoIosMenu, IoIosClose } from 'react-icons/io';

import { 
    Nav, 
    NavbarContainer, 
    NavLogo, 
    // MobileIcon, 
    MobileIconMenu,
    MobileIconClose,
    NavMenu, 
    NavItem, 
    NavLinks,
    MyButton_Nav
} from './NavbarElements';

import logo_black from "../../logo/logo_black.png";
import logo_white from "../../logo/logo_white.png"

// function returnMobileIcon({isOpen}) {
//     if (isOpen) {
//         return <IoIosClose style={{ fill: 'black' }}/>;
//     }
//     else return <IoIosMenu style={{ fill: 'black' }}/>;
// };

const Navbar = ({isOpen, toggle}) => {
    return (
        <>
            <Nav isOpen={isOpen}>
                <NavbarContainer isOpen={isOpen}>
                    <NavLogo isOpen={isOpen} to='/'>
                        <img src={logo_black} class="" alt="Timotej Iliev logo"/>
                    </NavLogo>
                    
                    <MobileIconMenu isOpen={isOpen} onClick={toggle}>
                        <IoIosMenu style={{ fill: 'black' }}/>
                    </MobileIconMenu>

                    <MobileIconClose isOpen={isOpen} onClick={toggle}>
                        <IoIosClose style={{ fill: 'black' }}/>
                    </MobileIconClose>

                    <NavMenu>
                        <NavItem>
                            <NavLinks smooth to='/#about_me' smooth={true} duration={500} spy={true} offset={-100}>About me</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks smooth to='/#work' smooth={true} duration={500} spy={true} offset={-100}>Work</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks smooth to='/#contact' smooth={true} duration={500} spy={true}>Contact</NavLinks>
                        </NavItem>
                        <NavItem>
                            <a href="https://drive.google.com/file/d/1Pc-N8z2S3vuJ-XzXkkb1J9wCVTRVAW48/view?usp=sharing" target="_blank">
                                <MyButton_Nav>
                                    View Resume
                                </MyButton_Nav>
                            </a>
                        </NavItem>
                    </NavMenu>

                    {/* <NavMenu>
                        <NavItem>
                            <NavLinks to='about_me' smooth={true} duration={500} spy={true} offset={-100}>About me</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to='work' smooth={true} duration={500} spy={true} offset={-100}>Work</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to='/'>Resume</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to='contact' smooth={true} duration={500} spy={true}>Contact</NavLinks>
                        </NavItem>
                    </NavMenu> */}
                </NavbarContainer>
            </Nav>
        </>
    );
};

export default Navbar


