import styled from "styled-components";


export const FooterContainer = styled.div`
    width: 100%;
    background-color: #FAFAFA;
`

export const FooterContent = styled.div`
    display: flex;
    flex-direction: column;
    /* justify-content: center; */

    max-width: 1200px;
    margin: 0 auto;

    /* height: 600px; */
    /* background-color: lightskyblue; */
`

export const FooterMargin = styled.div`
    margin: 0 24px;
`

export const SayHi = styled.h2`
    font-size: 25px;
    font-weight: 300;
    margin-top: 5rem;
    margin-bottom: 1rem;
    
    /* margin: 0 24px; */
`

export const LargeEmail = styled.h1`
    font-size: 30px;
    font-weight: 700;
    
    margin-top: 1rem;
    margin-bottom: 1rem;

    /* margin: 1rem 24px; */
`

export const FooterInfo = styled.h4`
    font-size: 18px;
    font-weight: 400;
    max-width: 500px;

    margin-bottom: 1rem;
    /* margin-top: 0.8rem; */
`

export const SocialIcons = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;

    /* font-size: 25px; */

    margin: 3rem auto;
`

export const SocialItem = styled.div`
    font-size: 25px;
    margin-right: 2rem;

    margin-top: 1rem;
    margin-bottom: 1rem;

    opacity: 70%;

    transition: 0.3s all ease;
    &:hover {
        opacity: 100%;
    }
`

export const RoundedLine = styled.div`
    width: 100%;
    height: 3px;
    background-color: #3A3A3A;
    opacity: 50%;
    border-radius: 10px;
`

export const MyButton = styled.button`
    background-color: none;
    color: #3A3A3A;
    
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    
    border: 2px #3A3A3A solid;
    padding: 8px 24px;
    margin-top: -2px;
    
    vertical-align: middle;

    display: inline-block;
    
    border-radius: 25px;

    transition: 0.3s all ease;

    opacity: 70%;

    &:hover {
        /* background-color: #22A6FF; */ // blue
        background-color: black;
        color: white;
        border: 2px rgba(255, 0, 0, 0) solid;
        cursor: pointer;
        opacity: 90%;
    }
`

export const FootnoteContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 4rem 0 5rem;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        justify-content: flex-start;
    }
`

export const FootnoteContentLeft = styled.p`
    font-size: 16px;
    font-weight: 400;
    opacity: 80%;
    text-align: left;
`

export const FootnoteContentRight = styled.p`
    font-size: 16px;
    font-weight: 400;
    opacity: 80%;
    text-align: right;

    @media screen and (max-width: 768px) {
        text-align: left;
        margin-top: 2rem;
    }
`