import React from 'react'

import { 
    AboutMeContainer,
    AboutMeFullScreenContent,
    AboutMeRegularContent,
    AboutMeHeaderContainer,
    LargeText,
    InfoText,
    SkillsSection,
    SkillType,
    SkillTypeHeaderContainer,
    ExpertHeader,
    ExpertContent,
    SolidHeader,
    SolidContent,
    FamiliarHeader,
    FamiliarContent,
    ProficienyHeader,
    SkillItem
} from './AboutMeElements';

import ScrollIcon from "../ScrollIcon";

import about_me_header_image from "../../images/about_me/about_me_pins_and_stickers_header.png";
import about_me_header_image_mobile from "../../images/about_me/about_me_pins_and_stickers_header_mobile.png";
import profile_picture from "../../images/about_me/profile_picture.jpg";
// import waving_hand_emoji from "../../images/about_me/waving_hand_emoji.png";
import waving_hand_emoji from "../../images/about_me/hand_wave.gif";
import UF_seal from "../../images/about_me/UF_seal.png";
import { MarginElement1, MarginElement2, MarginElement3, MarginElement4 } from '../CaseStudies/CaseStudyElements';


const Hero = () => {
    return (
        <>
            <AboutMeContainer id="about_me" class="fadein_on_land"> 
                <AboutMeFullScreenContent>
                    <AboutMeHeaderContainer>
                        <img src={about_me_header_image} style={{minWidth: 1100, maxWidth: 1250}} class="large_screen" alt="About me header image"/>
                        <img src={about_me_header_image_mobile} style={{minWidth: 500, maxWidth: 600}} class="small_screen" alt="About me header image"/>
                    </AboutMeHeaderContainer>
                </AboutMeFullScreenContent>
                
                <MarginElement2 />

                <AboutMeRegularContent>
                    <LargeText>
                        <span><img src={waving_hand_emoji} class="span_picture" alt="Waving hand emoji"/>&nbsp;</span>Hi I'm                        
                        <span>&nbsp;&nbsp;<img src={profile_picture} class="span_picture border_radius_50" alt="Profile picture"/>&nbsp;</span>
                        <span class="bold_text blue_text">Tim</span>
                        
                        . I'm a front-end developer, designer and user experience researcher.
                    </LargeText>
                    <LargeText>
                        I recently graduated from 
                        <span>&nbsp;&nbsp;<img src={UF_seal} class="span_picture" alt="The University of Findlay official seal"/>&nbsp;</span>
                        <span class="bold_text">
                            <a href="https://www.findlay.edu/" target="_blank" class="orange_text underline_on_hover">
                                The University of Findlay
                            </a>
                        </span> with a BSc. in Computer Science and am 
                        looking for a full time position in web development/ design.
                    </LargeText>

                    <InfoText>
                        During the last four years I have designed logos, newspapers, mobile applications, websites 
                        and have created custom artwork for various organizations based in the United States and Europe. 
                        Although I have an understanding of back-end development, my skills lie in front-end. 
                        I am able to convert designs into functional, responsive and scalable code.
                    </InfoText>

                    <SkillsSection>
                        <SkillType>
                            <SkillTypeHeaderContainer>Engineering skills</SkillTypeHeaderContainer>

                            <ExpertHeader><ProficienyHeader>Expertise in</ProficienyHeader></ExpertHeader>
                            <ExpertContent>
                                <SkillItem>HTML</SkillItem>
                                <SkillItem>CSS</SkillItem>
                                <SkillItem>JavaScript</SkillItem>
                            </ExpertContent>
                            
                            <SolidHeader><ProficienyHeader>Solid understanding of</ProficienyHeader></SolidHeader>
                            <SolidContent>
                                <SkillItem>ReactJS</SkillItem>
                                <SkillItem>jQuery</SkillItem>
                                <SkillItem>SCSS</SkillItem>
                                <SkillItem>Bootstrap</SkillItem>
                                <SkillItem>C++</SkillItem>
                                <SkillItem>Python</SkillItem>
                                <SkillItem>Django</SkillItem>
                            </SolidContent>

                            <FamiliarHeader><ProficienyHeader>Familiar with</ProficienyHeader></FamiliarHeader>
                            <FamiliarContent>
                                <SkillItem>PHP</SkillItem>
                                <SkillItem>Java</SkillItem>
                                <SkillItem>ES6</SkillItem>
                                <SkillItem>SQL</SkillItem>
                                <SkillItem>Git</SkillItem>
                            </FamiliarContent>
                        </SkillType>

                        <SkillType>
                            <SkillTypeHeaderContainer>Design skills</SkillTypeHeaderContainer>

                            <ExpertHeader><ProficienyHeader>Expertise in</ProficienyHeader></ExpertHeader>
                            <ExpertContent>
                                <SkillItem>Adobe Photoshop</SkillItem>
                                <SkillItem>Illustrator</SkillItem>
                                <SkillItem>XD</SkillItem>
                                <SkillItem>InDesign</SkillItem>
                                <SkillItem>After Effects</SkillItem>
                            </ExpertContent>
                            
                            <SolidHeader><ProficienyHeader>Solid understanding of</ProficienyHeader></SolidHeader>
                            <SolidContent>
                                <SkillItem>Interaction Design</SkillItem>
                                <SkillItem>User Research</SkillItem>
                                <SkillItem>Usability Testing</SkillItem>
                            </SolidContent>

                            <FamiliarHeader><ProficienyHeader>Familiar with</ProficienyHeader></FamiliarHeader>
                            <FamiliarContent>
                                <SkillItem>Figma</SkillItem>
                                <SkillItem>InVision</SkillItem>
                            </FamiliarContent>
                        </SkillType>
                    </SkillsSection>
                    
                </AboutMeRegularContent>
            </AboutMeContainer>
        </>
    )
}

export default Hero