import styled from 'styled-components'

export const AboutMeContainer = styled.div`
    width: 100%;
    margin-top: -100px;

    @media screen and (max-width: 748px) {
        margin-top: -110px;
    }

    @media screen and (max-width: 480px) {
        margin-top: -180px;
    }
`

export const AboutMeFullScreenContent = styled.div`
    display: flex;
    justify-content: center;
    /* margin-top: -50px; */
`

export const AboutMeHeaderContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;

    @media screen and (max-width: 480px) {
        margin-left: -20px;
    }
`

export const AboutMeRegularContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    max-width: 1200px;
    margin: 0 auto;
`

export const LargeText = styled.h1`
    font-size: 40px;
    font-weight: 400;
    margin: 2rem 24px 1rem;

    @media screen and (max-width: 748px) {
        font-size: 35px;
    }

    @media screen and (max-width: 480px) {
        font-size: 30px;
    }
`

export const InfoText = styled.p`
    font-size: 20px;
    font-weight: 400;
    line-height: 2.2rem;

    margin: 2rem 24px 6rem;
`

export const SkillsSection = styled.div`
    /* width: 100%; */
    /* height: 400px; */
    margin: 0 24px 4rem;

    /* border: 2px black solid; */
`

export const SkillType = styled.div`
    display: grid;
    grid-template-columns: 215px 1fr;
    grid-template-rows: repeat(4, auto);
    grid-template-areas:
        "SkillTypeHeaderContainer ."
        "ExpertHeader ExpertContent"
        "SolidHeader SolidContent"
        "FamiliarHeader FamiliarContent";
    grid-row-gap: 12px;
    grid-column-gap: 16px;
    margin-bottom: 4rem;

    @media screen and (max-width: 700px) {
        display: flex;
        flex-direction: column;
    }
`

export const SkillTypeHeaderContainer = styled.div`
    grid-area: SkillTypeHeaderContainer;
    justify-self: flex-end;
    align-self: flex-end;
    /* margin-bottom: 10px; */

    font-size: 22px;
    font-weight: 700;

    @media screen and (max-width: 700px) {
        display: flex;
        justify-self: flex-start;
        align-self: flex-start;
    }
`

export const ExpertHeader = styled.div`
    grid-area: ExpertHeader;
    align-self: flex-start;
    justify-self: flex-end;

    margin-top: 6px;

    @media screen and (max-width: 700px) {
        display: flex;
        justify-self: flex-start;
        align-self: flex-start;
    }
`

export const ExpertContent = styled.div`
    grid-area: ExpertContent;
`

export const SolidHeader = styled.div`
    grid-area: SolidHeader;
    align-self: flex-start;
    justify-self: flex-end;

    margin-top: 6px;

    @media screen and (max-width: 700px) {
        display: flex;
        justify-self: flex-start;
        align-self: flex-start;
    }
`

export const SolidContent = styled.div`
    grid-area: SolidContent;
`

export const FamiliarHeader = styled.div`
    grid-area: FamiliarHeader;
    align-self: flex-start;
    justify-self: flex-end;

    margin-top: 6px;

    @media screen and (max-width: 700px) {
        display: flex;
        justify-self: flex-start;
        align-self: flex-start;
    }
`

export const FamiliarContent = styled.div`
    grid-area: FamiliarContent;
`

export const ProficienyHeader = styled.h2`
    font-size: 20px;
    font-weight: 400;
    text-align: right;
    /* vertical-align: middle; */

    @media screen and (max-width: 700px) {
        display: flex;
        justify-self: flex-start;
        align-self: flex-start;

        margin-bottom: -8px;
    }
`

export const SkillItem = styled.div`
    background-color: #A3DAFF;
    color: #22A6FF;
    
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    
    border: none;
    padding: 8px 18px;
    margin-left: 0;
    margin-right: 6px;
    margin-top: 6px;
    margin-bottom: 6px;
    
    display: inline-block;
    
    border-radius: 21px;

    @media screen and (max-width: 700px) {
        margin-top: 6px;
        margin-bottom: 6px;
        margin-left: 0;
        margin-right: 12px;
    }
`