import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch } from 'react-router-dom';

// import HeroSection from '../components/HeroSection';
// import ProjectPreview from '../components/ProjectPreview';
// import Navbar from '../components/Navbar';
// import Sidebar from '../components/Sidebar';
// import About from "../components/About";
// import Footer from "../components/Footer"

// import { 
//     ThePulseInfo, 
//     RavensInfo,
//     WoodCountyInfo,
//     BrewWithTheCrewInfo
// } from "../components/ProjectPreview/Data";
// import { Info, Slim } from "../components/CaseStudy/CaseStudyElements";

import Hero from "../components/Hero";
import AboutMe from "../components/AboutMe";
import Work from "../components/Work";

const Home = () => {
    return (
        <>
            <Hero />
            <AboutMe />
            <Work />
        </>
    )
}

export default Home
