import styled from 'styled-components'
import { Link as LinkRouter } from 'react-router-dom';

export const CaseStudyContainer = styled.div`
    width: 100%;
    /* height: 100vh; */
    /* background-color: lightskyblue; */
    margin-top: -60px;
`

export const CaseStudyFullScreenContent = styled.div`
    display: flex;
    flex-direction: column;
    /* align-items: center; */
`

export const CaseStudyRegularContent = styled.div`
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */

    max-width: 1200px;
    margin: 0 auto;
`

export const CaseStudyMargin = styled.div`
    margin: auto 15% auto;

    @media only screen and (max-width: 768px) {
        margin: auto 24px auto;
    }
`

export const CaseStudySmallerMargin = styled.div`
    margin: auto 10% auto;

@media only screen and (max-width: 768px) {
    margin: auto 24px auto;
}
`

export const CaseStudyHeaderImageContainer = styled.div`
    margin-top: 10vh;
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;

    /* @media only screen and (max-width: 600px) {
        margin-top: 15vh;
    } */
`
export const CaseStudyFullScreenImageContainer = styled.div`
    /* margin-top: 10vh; */
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
`

export const FullViewHeightContainer = styled.div`
    height: 100vh;
`

export const FeaturedCaseStudyTag = styled.p`
    font-weight: 300;
    margin-top: 1rem;
    margin-bottom: 1.2rem;
    opacity: 50%;
`

export const CaseStudyTitle = styled.h1`
    font-size: 40px;
    font-weight: 600;
    text-align: center;
    line-height: normal;
`

export const CaseStudyQuickInfoContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* grid-template-rows: 1fr 1fr 1fr; */
    grid-column-gap: 3rem;
    grid-row-gap: 2rem;
    /* margin: 4rem 0; */

    grid-template-areas: 
        "Company Platform"
        "Role Duration"
        "Tools Tools";

    @media only screen and (max-width: 600px) {
        display: flex;
        flex-direction: column;
    }
`

export const QuickInfo = styled.div`
    
`
export const QuickInfoCompany = styled.div` grid-area: Company;`
export const QuickInfoPlatform = styled.div` grid-area: Platform;`
export const QuickInfoRole = styled.div` grid-area: Role;`
export const QuickInfoDuration = styled.div` grid-area: Duration;`
export const QuickInfoTools = styled.div` grid-area: Tools;`

export const QuickInfoHeader = styled.h3`
    font-size: 16px;
    font-weight: 600;
`

export const QuickInfoDetails = styled.h3`
    font-size: 16px;
    font-weight: 300;

    margin-top: 0.2rem;
`

export const CaseStudyHeaderText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const SectionHeader = styled.h2`
    font-size: 20px;
    font-weight: 600;
    /* color: #22A6FF; */

    margin: 4rem auto 0.8rem;
`

export const Info = styled.h3`
    font-size: 18px;
    font-weight: 400;
    line-height: 1.8rem;

    /* margin: auto 15%; */
`

export const ImageSideInfoContainer = styled.div`
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-column-gap: 4rem;
    margin: 4rem 0;

    grid-template-areas: 
        "SideImageAndCaption ImgExplanationContainer";

    @media only screen and (max-width: 600px) {
        display: flex;
        flex-direction: column;
    }
`

export const SideImageAndCaption = styled.div`
    display: flex;
    flex-direction: column;
    
    grid-area: SideImageAndCaption;
/* 
    @media only screen and (max-width: 800px) {
        width: 100%;
    } */
`

export const ImgCaption = styled.p`
    opacity: 50%;
    margin-top: 1rem;
`

export const ImgExplanationContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 25px;
    color: #FFA122;
    font-weight: 500;

    grid-area: ImgExplanationContainer;

    /* align-items: center; */
    /* margin-left: 2rem; */
    /* height: 400px; */

    @media only screen and (max-width: 600px) {
        /* width: 100%; */
        margin-top: 3rem;
        /* margin-left: 0; */
    } 
`

export const MarginElement1 = styled.div`
    margin-top: 1rem;
    width: 0%;
    height: 0%;
`

export const MarginElement2 = styled.div`
    margin-top: 2rem;
    width: 0%;
    height: 0%;
`

export const MarginElement3 = styled.div`
    margin-top: 3rem;
    width: 0%;
    height: 0%;
`

export const MarginElement4 = styled.div`
    margin-top: 4rem;
    width: 0%;
    height: 0%;
`

export const AwardItem = styled.div`
    background-color: #A3DAFF;
    /* color: #0266AA; */
    color: #1A7DBF;
    
    border: none;
    padding: 15px 15px;
    margin-top: 0.5rem;
    /* margin: 6px 6px; */
    
    display: inline-block;
    
    border-radius: 36px;
`

export const AwardInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    /* align-items: flex-start; */

    @media screen and (max-width: 400px) {
        /* flex-direction: column; */
    }
`

export const AwardInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: auto 0.5rem;
`

export const AwardIssuedBy = styled.p`
    font-size: 16px;
    font-weight: 600;
`

export const AwardName = styled.p`
    font-size: 14px;
    font-weight: 400;
`

export const MyButton_Back = styled(LinkRouter)`
    background-color: none;
    color: black;
    
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    
    border: 2px black solid;
    padding: 8px 24px;
    margin-top: -2px;
    
    vertical-align: middle;

    display: inline-block;
    
    border-radius: 25px;

    transition: 0.3s all ease;

    opacity: 70%;

    &:hover {
        /* background-color: #22A6FF; */ // blue
        /* background-color: #FDE727; */
        background-color: #FFA122;
        color: white;
        border: 2px rgba(255, 0, 0, 0) solid;
        cursor: pointer;
        opacity: 90%;
    }
`

export const DivCenterer = styled.div`
    display: flex;
    justify-content: center;
`