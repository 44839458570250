import React from 'react';
import { FaDribbble, FaGithub, FaLinkedinIn, FaMediumM } from 'react-icons/fa';

import { 
    FooterContainer,
    FooterContent,
    FooterMargin,
    SayHi,
    LargeEmail,
    FooterInfo,
    SocialIcons,
    SocialItem,
    RoundedLine,
    MyButton,
    FootnoteContainer,
    FootnoteContentLeft,
    FootnoteContentRight
} from './FooterElements';

const Footer = () => {
    return (
        <>
            <FooterContainer id="contact" class="fadein_on_land">
                <FooterContent>
                    <FooterMargin>
                        <SayHi>Say hi!</SayHi>
                        <LargeEmail>
                            <a href = "mailto: timotejiliev@gmail.com" class="large_screen">
                                timotejiliev@gmail.com
                            </a>
                            <a href = "mailto: timotejiliev@gmail.com" class="small_screen">
                                timotejiliev<br/>@gmail.com
                            </a>
                        </LargeEmail>

                        <FooterInfo>
                            I'm currently open for work and am looking for opportunities 
                            in front-end development and UX design.
                        </FooterInfo>

                        <SocialIcons>
                            <SocialItem>
                                <a href="https://dribbble.com/Tim_iliev" target="_blank">
                                    <FaDribbble style={{ fill: 'black', marginRight: 10}}/>
                                </a>
                            </SocialItem>
                            <SocialItem>
                                <a href="https://github.com/timiliev" target="_blank">
                                    <FaGithub style={{ fill: 'black', marginRight: 10 }}/>
                                </a>
                            </SocialItem>
                            <SocialItem>
                                <a href="https://www.linkedin.com/in/timotejiliev/" target="_blank">
                                    <FaLinkedinIn style={{ fill: 'black', marginRight: 10 }}/>
                                </a>
                            </SocialItem>
                            <SocialItem>
                                <a href="https://medium.com/@timiiliev" target="_blank">
                                    <FaMediumM style={{ fill: 'black', marginRight: 10 }}/>
                                </a>
                            </SocialItem>     

                            <a href="https://drive.google.com/file/d/1Pc-N8z2S3vuJ-XzXkkb1J9wCVTRVAW48/view?usp=sharing" target="_blank">
                                <MyButton>
                                    View Resume
                                </MyButton>                       
                            </a>
                        </SocialIcons>

                        <RoundedLine />

                        <FootnoteContainer>
                            <FootnoteContentLeft>
                                Designed in Adobe XD. Developed with ReactJS.
                            </FootnoteContentLeft>

                            <FootnoteContentRight>© {new Date().getFullYear()} Timotej Iliev</FootnoteContentRight>
                        </FootnoteContainer>
                    </FooterMargin>
                </FooterContent>
            </FooterContainer>
        </>
    )
}

export default Footer
