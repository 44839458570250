import React from 'react'
import { Link as LinkRouter } from 'react-router-dom';

import { 

} from './ThePulseElements';

import {
    CaseStudyContainer,
    CaseStudyFullScreenContent,
    CaseStudyRegularContent,
    CaseStudyMargin,
    CaseStudySmallerMargin,
    CaseStudyHeaderImageContainer,
    CaseStudyFullScreenImageContainer,
    FeaturedCaseStudyTag,
    CaseStudyTitle,
    CaseStudyHeaderText,
    FullViewHeightContainer,
    CaseStudyQuickInfoContainer,
    QuickInfo,
    QuickInfoCompany,
    QuickInfoPlatform,
    QuickInfoRole,
    QuickInfoDuration,
    QuickInfoTools,
    QuickInfoHeader,
    QuickInfoDetails,
    SectionHeader,
    Info,
    ImageSideInfoContainer,
    SideImageAndCaption,
    ImgCaption,
    ImgExplanationContainer,
    MarginElement1,
    MarginElement2,
    MarginElement3,
    MarginElement4,
    AwardItem,
    AwardInfoContainer,
    AwardInfo,
    AwardIssuedBy,
    AwardName,
    MyButton_Back,
    DivCenterer
} from "../CaseStudyElements";

import {
    SkillItem
} from "../../AboutMe/AboutMeElements"

import {
    MoreCaseStudiesHeader,
    ProjectPreviewsContainer,
    ProjectPreviewTitle,
    ProjectPreviewDescription,
    ProjectPreviewTags,
    ProjectPreview
} from "../../Work/WorkElements";

import the_pulse_main_header from "../../../images/the_pulse/transparent_newspapers_main_header.png";
import party_blob from "../../../images/work/party_blob.gif";

import old_newspapers from "../../../images/the_pulse/old_newspapers.png";
import redesigned_newspapers from "../../../images/the_pulse/redesigned_newspapers.png";

import old_logo_iphone from "../../../images/the_pulse/old_logo_iphone_mockup.png";
import new_logo_iphone from "../../../images/the_pulse/new_logo_iphone_mockup.png";

import old_logo_highlighted from "../../../images/the_pulse/old_logo_highlighted.png";
import new_logo_responsiveness from "../../../images/the_pulse/new_logo_responsiveness.png";

import new_paper_analysis_fonts from "../../../images/the_pulse/new_paper_analysis_fonts.png";

import instagram_post_summary_1 from "../../../images/the_pulse/instagram_post_summary_1.png";
import instagram_post_summary_2 from "../../../images/the_pulse/instagram_post_summary_2.png";
import instagram_post_holiday from "../../../images/the_pulse/instagram_post_holiday.png";
import instagram_post_events from "../../../images/the_pulse/instagram_post_events.png";
import instagram_screenshots from "../../../images/the_pulse/instagram_screenshots.png";

import transparent_trophy_cover from "../../../images/the_pulse/transparent_trophy_cover.png";
import trophy_cover_large from "../../../images/the_pulse/award_cover_2.png";
import cute_trophy from "../../../images/the_pulse/cute_trophy.gif";
import onma_logo_square from "../../../images/the_pulse/onma_logo_square.png";

import ravens_tile from "../../../images/tiles/ravens_tile_cover.png";
import WCHS_tile from "../../../images/tiles/wchs_tile_cover.png";
import { MyButton } from '../../Footer/FooterElements';

const ThePulse = () => {
    return (
        <>
            <CaseStudyContainer>

{/* HEADER */}

                <FullViewHeightContainer class="fadein_on_land">

                    <CaseStudyFullScreenContent>
                        <CaseStudyHeaderImageContainer>
                            <img 
                                src={the_pulse_main_header} 
                                style={{minWidth: 1800, maxWidth: 2500}} 
                                class="" 
                                alt="The Pulse header image"
                                />
                        </CaseStudyHeaderImageContainer>
                    </CaseStudyFullScreenContent>

                    <CaseStudyHeaderText>
                        <FeaturedCaseStudyTag>
                            <span>
                                <img 
                                    src={party_blob} 
                                    // class="span_picture_small opacity_emoji"
                                    class="span_picture_small"  
                                    alt="Waving hand emoji"/>
                            </span>
                                &nbsp;&nbsp;
                            {/* <span class="opacity_50">Featured Case Study</span> */}
                            <span>Featured Case Study</span>
                        </FeaturedCaseStudyTag>

                        <CaseStudyTitle>
                            Redesigning a university<br/>newspaper
                        </CaseStudyTitle>
                    </CaseStudyHeaderText>

                </FullViewHeightContainer>

{/* CONTENT STARTS HERE */}

                <CaseStudyRegularContent>                     
                    <CaseStudyMargin>
                        <CaseStudyQuickInfoContainer>
                            <QuickInfoCompany id="QI_company">
                                <QuickInfoHeader>Company</QuickInfoHeader>
                                <QuickInfoDetails>The Pulse  /  The University of Findlay</QuickInfoDetails>
                            </QuickInfoCompany>

                            <QuickInfoPlatform id="QI_platform">
                                <QuickInfoHeader>Platform</QuickInfoHeader>
                                <QuickInfoDetails>Editorial Design  /  Web Design  /  Graphic Design  /  WordPress</QuickInfoDetails>
                            </QuickInfoPlatform>

                            <QuickInfoRole id="QI_role">
                                <QuickInfoHeader>Role</QuickInfoHeader>
                                <QuickInfoDetails>Design Editor</QuickInfoDetails>
                            </QuickInfoRole>

                            <QuickInfoDuration id="QI_duration">
                                <QuickInfoHeader>Duration</QuickInfoHeader>
                                <QuickInfoDetails>August 2019 – June 2021</QuickInfoDetails>
                            </QuickInfoDuration>

                            <QuickInfoTools id="QI_tools">
                                <QuickInfoHeader>Tools Used</QuickInfoHeader>
                                <QuickInfoDetails>
                                    <SkillItem>Photoshop</SkillItem>
                                    <SkillItem>Illustrator</SkillItem>
                                    <SkillItem>InDesign</SkillItem>
                                    <SkillItem>After Effects</SkillItem>
                                    <SkillItem>Adobe XD</SkillItem>
                                    <SkillItem>WordPress</SkillItem>
                                </QuickInfoDetails>
                            </QuickInfoTools>
                        </CaseStudyQuickInfoContainer>


                        <SectionHeader>Goal</SectionHeader>
                        <Info>
                            During the onboarding process, I was tasked with refreshing The Pulse brand. 
                            I was given freedom to redesign the logo, the newspaper layout and implement 
                            responsive design changes to The Pulse's WordPress based website. 
                            <br/><br/>
                            
                            The goal of the project was to make the newspaper more recognizable and 
                            accessible to the University of Findlay's student population.
                        </Info>

                        <SectionHeader>Key focus areas</SectionHeader>
                        <Info>
                            ━━&nbsp;&nbsp;&nbsp;Modernize The Pulse logo for digital usage. <br />
                            ━━&nbsp;&nbsp;&nbsp;Refresh the look of the print edition of the newspaper. <br />
                            ━━&nbsp;&nbsp;&nbsp;Update The Pulse's social media brand.
                        </Info>

                        <SectionHeader>Research</SectionHeader>
                        <Info>
                            While joining this role, I decided to speak with the previous Design Editor to 
                            ask for guidance and brainstorm areas of improvement. 
                            We discussed the layout of The Pulse, the adaptability of the logo, 
                            and the lack of mobile responsiveness when it came to the website.
                        </Info>

                    </CaseStudyMargin>
                </CaseStudyRegularContent>

                <MarginElement2 />

                <CaseStudyFullScreenContent>
                    <CaseStudyFullScreenImageContainer>
                        <img 
                            src={old_newspapers} 
                            style={{minWidth: 600, maxWidth: 2000}} 
                            class="" 
                            alt="The Pulse newspaper before the redesign"
                            />
                    </CaseStudyFullScreenImageContainer>
                </CaseStudyFullScreenContent>

                <MarginElement2 />

                <CaseStudyRegularContent>
                    <CaseStudyMargin>
                        <Info>
                            We identified four areas where the layout of the newspaper could be improved. 
                            Namely, item alignment, white space usage, font selection and styling consistency. 
                        </Info>

                        <ImageSideInfoContainer>
                            <SideImageAndCaption>
                                <img src={old_logo_iphone} alt="Old pulse logo on Instagram" class="side_image_width"/>
                                <ImgCaption>Logo analysis #1</ImgCaption>
                            </SideImageAndCaption>

                            <ImgExplanationContainer>
                                The former logo fails to adapt to smaller sizes.
                            </ImgExplanationContainer>
                        </ImageSideInfoContainer>


                        <SectionHeader>Ideation</SectionHeader>
                            <Info>
                                Newer logos typically have variations that are easily deconstructed. 
                                For the redesign to be successful, we needed to identify the components 
                                that made The Pulse logo and deconstruct the original. 
                                Two areas were identified. The redesign could either focus on minimal 
                                typography or highlight the pulse icon. While consulting The Pulse staff, 
                                it was agreed upon that the component of focus should be the icon itself.
                            </Info>
                    </CaseStudyMargin>

                    <CaseStudySmallerMargin>
                        <img 
                            src={old_logo_highlighted} 
                            alt="Old pulse logo, analysis #2 - Responsiveness" 
                            class=""
                        />
                    </CaseStudySmallerMargin>

                    <CaseStudyMargin>
                        <ImgCaption>Logo analysis #2</ImgCaption>

                    <MarginElement1 />
                    <Info>
                        Furthermore, while looking at other newspapers for inspiration, 
                        I noticed a common pattern of serif fonts incorporated in the logo design. 
                        I decided to explore this further in the design process.
                    </Info>

                    <SectionHeader>Solution</SectionHeader>
                            <Info>
                                An important requirement of logo redesign was the ability of the cardiogram symbol
                                to be easily separated from the logo typography. 
                                In order to do so, the cardiogram symbol was encapsulated in a circle. 
                                The new form allowed it to stand on its own in situations where this was required. 
                                Additionally, this allowed for different variants of the logo to be easily created.
                            </Info>

                    </CaseStudyMargin>

                    <MarginElement2 />
                    <CaseStudySmallerMargin>
                        <img 
                            src={new_logo_responsiveness} 
                            alt="" 
                            class="orange_border"
                        />
                    </CaseStudySmallerMargin>

                    <CaseStudyMargin>
                        <ImgCaption>The Pulse logo redesign</ImgCaption>

                        <MarginElement2 />
                        <Info>
                            The orange accent color used for the logo and throughout 
                            the magazine was inspired by The University of Findlay branding guidelines.
                        </Info>
                    </CaseStudyMargin>
                </CaseStudyRegularContent>

                <MarginElement2 />

                <CaseStudyFullScreenContent>
                    <CaseStudyFullScreenImageContainer>
                        <img 
                            src={redesigned_newspapers} 
                            style={{minWidth: 600, maxWidth: 2000}} 
                            class="" 
                            alt="The Pulse newspaper redesign"
                            />
                    </CaseStudyFullScreenImageContainer>
                </CaseStudyFullScreenContent>

                <MarginElement2 />

                <CaseStudyRegularContent>

                    <CaseStudySmallerMargin>
                        <img 
                            src={new_paper_analysis_fonts} 
                            alt="" 
                            class="The pulse newspaper redesign: font analysis"
                        />
                    </CaseStudySmallerMargin>

                    <CaseStudyMargin>
                        <ImgCaption>The Pulse newspaper redesign: Font selection</ImgCaption>

                        <MarginElement2/>
                        <Info>
                            Leitura News Roman was used for all headers and sub-headers throughout the newspaper. 
                            Baskerville MT was used for all article text. 
                            Circular Std was used for The Pulse designed ads. 
                            Finally, SF Pro Display was the other sans-serif font, used mainly author attribution on articles.
                        </Info>

                        <ImageSideInfoContainer>
                            <SideImageAndCaption>
                                <img src={new_logo_iphone} alt="The new pulse logo on Instagram" class="side_image_width"/>
                                <ImgCaption>The new pulse logo: Instagram</ImgCaption>
                            </SideImageAndCaption>

                            <ImgExplanationContainer>
                                The redesigned logo has variations that support smaller sizes.
                            </ImgExplanationContainer>
                        </ImageSideInfoContainer>

                        <MarginElement2/>
                        <SectionHeader>Social media redesign</SectionHeader>
                            <Info>
                                Along with the redesign of the logo and the layout of the newspaper, 
                                I also had the opportunity to work on the social media layout for The Pulse.
                            </Info>
                    </CaseStudyMargin>
                </CaseStudyRegularContent>

                <MarginElement3 />
                <CaseStudyFullScreenContent>
                    <CaseStudyFullScreenImageContainer>
                        <img 
                            src={instagram_screenshots} 
                            style={{minWidth: 600, maxWidth: 1300}} 
                            class="" 
                            alt="Instagram redesign, shown on iPhone screens"
                            />
                    </CaseStudyFullScreenImageContainer>
                </CaseStudyFullScreenContent>
                <MarginElement3 />

                <CaseStudyRegularContent>

                    <CaseStudyMargin>
                        <Info>   
                            I had the liberty to experiment with different designs and establish 
                            the social media guidelines for the new Pulse brand.
                            <br/><br/>

                            I decided to introduce three types of posts, being 
                            article posts, 
                            event posts and 
                            holiday posts. 
                            Each post having their own distinctive appearance, 
                            making them more recognizable at an initial glance.
                        </Info>
                    </CaseStudyMargin>

                    <MarginElement4 />
                    <CaseStudySmallerMargin>
                        <img 
                            src={instagram_post_summary_1} 
                            alt="Instagram redesign: Article summary post #1" 
                            class=""
                        />
                    </CaseStudySmallerMargin>
                    
                    <CaseStudyMargin>
                        <ImgCaption>Instagram redesign: Article summary post #1</ImgCaption>
    
                    <MarginElement3 />

                        <img 
                            src={instagram_post_summary_2} 
                            alt="Instagram redesign: Article summary post #2" 
                            class=""
                        />
                        <ImgCaption>Instagram redesign: Article summary post #2</ImgCaption>
                        
                        <MarginElement3 />

                        <img 
                            src={instagram_post_events} 
                            alt="Instagram redesign: Holiday post" 
                            class=""
                        />
                        <ImgCaption>Instagram redesign: Holiday post</ImgCaption>
                    
                        <MarginElement3 />

                        <img 
                            src={instagram_post_holiday} 
                            alt="Instagram redesign: Event post" 
                            class=""
                        />
                        <ImgCaption>Instagram redesign: Event post</ImgCaption>

                        <MarginElement2 />

                        <Info>   
                            The main font used for the social media posts is Akzidenz-Grotesk Extended Bold, 
                            while the supporting sans-serif used for paragraph text is SF Pro Display.
                        </Info>

                        <SectionHeader>Outcome</SectionHeader>
                            <Info>
                                I was a part of The Pulse staff for almost two years, 
                                and during this time I was able to extensively familiarize myself 
                                with Adobe InDesign and expand my knowledge using the rest of the Adobe Suite. 
                                Additionally, I performed routine maintained on The Pulse's website, 
                                and experimented with WordPress theme creation.
                                {/* <br/><br/> */}

                                {/* The rebranded Pulse won an&nbsp;
                                <span class="linear_wipe">Ohio News Media Association (ONMA)</span>
                                <span>
                                &nbsp;
                                <img 
                                src={cute_trophy} 
                                class="span_picture_small opacity_emoji" 
                                alt="Waving hand emoji"/>
                                </span>
                                &nbsp;
                                award 
                            for design for the school year 2019/2020. */}
                            </Info>

                            <SectionHeader>Awards&nbsp;
                                <span>
                                <img 
                                    src={cute_trophy} 
                                    class="span_picture_small opacity_emoji" 
                                    alt="Waving hand emoji"/>
                                </span>
                            </SectionHeader>
                                <AwardItem>
                                    <AwardInfoContainer>    
                                        {/* <span> */}
                                            {/* &nbsp;&nbsp; */}
                                            <img 
                                                src={onma_logo_square} 
                                                class="span_picture2 border_radius_50" 
                                                alt=""
                                                />
                                            &nbsp;
                                        {/* </span> */}
                                        <AwardInfo>
                                            <AwardIssuedBy>Ohio News Media Association</AwardIssuedBy>
                                            <AwardName>Design, 3rd</AwardName>
                                        </AwardInfo>
                                    </AwardInfoContainer>
                                </AwardItem>
                    </CaseStudyMargin>
                </CaseStudyRegularContent>

                <MarginElement2 />

                <CaseStudyFullScreenContent>
                    <CaseStudyFullScreenImageContainer>
                        <img 
                            src={transparent_trophy_cover} 
                            // src={trophy_cover_large} 
                            style={{minWidth: 600, maxWidth: 1500}} 
                            class="" 
                            alt="Floating trophies"
                            />
                    </CaseStudyFullScreenImageContainer>
                </CaseStudyFullScreenContent>

                <CaseStudyRegularContent>
                    <MarginElement4/>
                    <MoreCaseStudiesHeader>More case studies coming soon</MoreCaseStudiesHeader>
                        <ProjectPreviewsContainer>
                            <ProjectPreview>

                                <img 
                                    src={ravens_tile} 
                                    // style={{minWidth: 2000, maxWidth: 3000}} 
                                    // class="" 
                                    // alt="Work header image"
                                />

                                <ProjectPreviewTitle>
                                    Raven's Progressive Matrices
                                </ProjectPreviewTitle>

                                <ProjectPreviewDescription>
                                    A cross-platform compatible software for solving a non-verbal fluid intelligence test.
                                </ProjectPreviewDescription>     

                                <ProjectPreviewTags>
                                    UI Design / Front-end development / HCI Research
                                </ProjectPreviewTags> 

                            </ProjectPreview>
                            
                            <ProjectPreview>

                                <img 
                                    src={WCHS_tile} 
                                    // style={{minWidth: 2000, maxWidth: 3000}} 
                                    // class="" 
                                    // alt="Work header image"
                                />

                                <ProjectPreviewTitle>
                                    Donor Management Software
                                </ProjectPreviewTitle>

                                <ProjectPreviewDescription>
                                    A Customer Relationship Management (CRM) solution for&nbsp;
                                    <a href="https://www.wchumane.org/" target="_blank" class="blue_text underlined_text"> 
                                        Wood County Humane Society
                                    </a>
                                    .
                                </ProjectPreviewDescription>     

                                <ProjectPreviewTags>
                                    UI Design / Front-end development / Back-end development
                                </ProjectPreviewTags> 

                            </ProjectPreview>
                        </ProjectPreviewsContainer>
                        
                        <MarginElement4 />
                        <DivCenterer>
                            <MyButton_Back to="/">
                                Back to home page
                            </MyButton_Back>
                        </DivCenterer>
                        <MarginElement4 />
                        
                    </CaseStudyRegularContent>
            </CaseStudyContainer>
        </>
    )
}

export default ThePulse
