import styled from "styled-components";
import { FaTimes } from 'react-icons/fa';
import { Link as LinkScroll } from 'react-scroll';
import { HashLink } from 'react-router-hash-link';

export const SidebarContainer = styled.aside`
    display: none;

    @media screen and (max-width: 768px) {
        position: fixed;
        z-index: 9;
        width: 100%;
        height: 100%;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        top: 60px;
        left: 0;
        transition: 0.3s ease-in-out;
    
        /* display: none; */

        /* display: ${({ isOpen }) => (isOpen ? 'grid' : 'none')};  */
        
        opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
        top: ${({ isOpen }) => (isOpen ? '60px' : '-100%')};
    }
`

// export const CloseIcon = styled(FaTimes)`
//     display: block;
//     position: absolute;
//     top: 30px;
//     right: 30px;
//     transform: translate(-100%, 60%);
//     font-size: 1.8rem;
//     cursor: pointer;
//     color: #fff;
// `

export const SidebarInfo = styled.p`
    font-size: 14px;
    margin-bottom: 2rem;
    opacity: 50%;
`

export const SidebarMenu = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;
`

export const SidebarWrapper = styled.div`
    /* background-color: lightblue; */
    margin: 24px;
`

export const SidebarLink = styled(HashLink)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    color: #3A3A3A;
    cursor: pointer;
    
    &:hover {
        /* color: blue; */
        transition: 0.2s ease-in-out;
        text-decoration: underline;
    }
`

export const SidebarLink2 = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    color: #3A3A3A;
    cursor: pointer;
    
    &:hover {
        /* color: blue; */
        transition: 0.2s ease-in-out;
        text-decoration: underline;
    }
`

export const SideSocialIconsWrap = styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 2rem;
`

export const SocialLinks = styled.li`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    text-decoration: none;
    
    margin-bottom: 0.8rem;

    cursor: pointer;

    opacity: 70%;

    &:hover {
        text-decoration: underline;
        opacity: 100%;
        transition: 0.2s ease-in-out;
    }
`

export const AlignLink = styled.span`
    display: flex;
    align-items: center;
`