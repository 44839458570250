import styled from 'styled-components';
import { Link as LinkRouter } from 'react-router-dom';
import { Link as LinkScroll } from 'react-scroll';
import { HashLink } from 'react-router-hash-link';

export const Nav = styled.nav`
    background: white;
    height: 60px;
    /* margin-top: -60px;  */

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 1rem;

    position: sticky;
    top: 0;
    z-index:10;

    /* filter: drop-shadow(0px 6px 45px #FBFBFB); */

    filter: ${({ isOpen }) => (isOpen ? 'none' : 'drop-shadow(0px 6px 45px rgba(0, 0, 0, 0.05));')};
    transition: 0.3s ease-in-out;
    
    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
    }
`;

export const NavbarContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    height: 60px;
    width: 100%;
    
    z-index: 1;

    max-width: 1200px;

    /* margin: 0 24px; */
    padding: 0 24px;
    /* padding: 0 24px; */

    /* @media screen and (max-width: 480px) {
        width: 95%;;
    }  */
`;

export const NavLogo = styled(LinkRouter)`
    width: 80px;
    /* height: auto; */
    /* background-color: red; */
    
    display: flex;
    justify-self: flex-start;

    align-items: center;
    
    cursor: pointer;
    /* figure out fill property */
`;

export const MobileIconMenu = styled.div`
    display: none;
    
    @media screen and (max-width: 768px) {
        font-size: 1.8rem;

        /* display: flex; */

        align-items: center;

        height: 100%;
        /* height: 30px; */
        /* margin-left: 24px; */
    
        cursor: pointer;

        display: ${({ isOpen }) => (isOpen ? 'none' : 'flex')};
        transition: 0.3s ease-in-out;
    }
`

export const MobileIconClose = styled.div`
    display: none;
    
    @media screen and (max-width: 768px) {
        font-size: 1.8rem;

        /* display: flex; */

        align-items: center;

        height: 100%;
        /* height: 30px; */
        /* margin-left: 24px; */
    
        cursor: pointer;

        display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
        transition: 0.3s ease-in-out;
    }
`

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    justify-content: left;

    list-style: none;
    
    @media screen and (max-width: 768px) {
        display: none;
    }
`

export const NavItem = styled.li`
    height: 100%;
    font-size: 14px;
    margin-left: 2.5rem;
    display: flex;
`

export const NavLinks = styled(HashLink)`
    color: #3A3A3A;
    opacity: 50%;
    text-decoration: none;
    display: flex;
    align-items: center;
    
    height: 100%;
    padding: 0 1 rem;
    cursor: pointer;

    :hover {
        opacity: 100%;
    }

    /* &.active {
        opacity: 100%;
        border-bottom: 3px solid #01bf71;
    } */
`

export const MyButton_Nav = styled.button`
    background-color: none;
    color: #3A3A3A;
    
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
    
    /* border: 1.5px #3A3A3A solid; */
    border: 2px rgba(255, 0, 0, 0) solid;
    padding: 6px 16px;
    margin-top: -2px;
    
    vertical-align: middle;

    display: inline-block;
    
    border-radius: 25px;

    transition: 0.3s all ease;

    opacity: 50%;

    &:hover {
        /* background-color: #22A6FF; */ // blue
        background-color: black;
        color: white;
        border: 2px rgba(255, 0, 0, 0) solid;
        cursor: pointer;
        opacity: 90%;
    }
`